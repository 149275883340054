.receipt-form {
	padding: 2px;
}

.receipt-form > input {
	margin-left: 10px;
	height: 30px;
}

.receipt-form > select {
	min-height: 30px;
	height: 40px;
}

.receipt-form > button {
	padding: 5px 10px;

	outline: 1px solid black;
	border: none;
	margin-left: 10px;
}
